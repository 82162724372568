import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const KobrosText = () => (
  <Stack direction="row" alignItems="center">
    <Typography color={{ sx: 'fingoWhite', md: 'text.main' }} fontWeight="bold">
      ko
    </Typography>
    <Typography
      color={{ xs: 'fingoWhite', md: 'secondary.main' }}
      fontWeight="bold"
    >
      Bros
    </Typography>
  </Stack>
);

export default KobrosText;
