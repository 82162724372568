import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { useSelectedCompany } from '../../hooks';

const ExecutiveAssignedInfo = ({ addAlert }) => {
  const selectedCompany = useSelectedCompany();
  const executiveAssigned = selectedCompany ? selectedCompany.executiveAssigned : null;

  const darthVaderExecutiveAssigned = {
    ...executiveAssigned,
    email: 'claudia@fingo.cl',
    firstName: 'Claudia',
    lastName: 'de la Sotta',
    phoneNumber: '+56990783426',
  };

  const displayExecutiveAssigned = executiveAssigned?.email === 'esteban@fingo.cl' ? darthVaderExecutiveAssigned : executiveAssigned;

  return (
    <>
      { displayExecutiveAssigned && (
      <Stack
        justifyContent="center"
        direction="column"
        alignItems="flex-start"
        px={4}
        sx={{
          position: 'absolute',
          bottom: addAlert ? 110 : '69vh',
          left: 0,
          right: 0,
          zIndex: 99,
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            display: 'inline',
            color: 'background.light',
            mb: 2,
          }}
        >
          Mi ejecutivo:
        </Typography>
        <Typography variant="body1" align="center" sx={{ color: 'background.light' }}>
          {`${displayExecutiveAssigned.firstName} ${displayExecutiveAssigned.lastName}`}
        </Typography>
        {displayExecutiveAssigned.phoneNumber
        && (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', pt: 1 }}>
          <PhoneIcon sx={{ color: 'primary.main' }} />
          <Link
            href={`tel:${displayExecutiveAssigned.phoneNumber}`}
            underline="hover"
            sx={{ color: 'background.light', pl: 1 }}
          >
            {displayExecutiveAssigned.phoneNumber}
          </Link>
        </Box>
        )}
        {displayExecutiveAssigned.email
        && (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', pt: 1 }}>
          <EmailIcon sx={{ color: 'primary.main' }} />
          <Link
            href={`mailto:${displayExecutiveAssigned.email}`}
            underline="hover"
            sx={{ color: 'background.light', pl: 1 }}
          >
            {displayExecutiveAssigned.email}
          </Link>
        </Box>
        )}
      </Stack>
      )}
    </>
  );
};

ExecutiveAssignedInfo.propTypes = {
  addAlert: PropTypes.bool.isRequired,
};

export default ExecutiveAssignedInfo;
